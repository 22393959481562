import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';
import { color } from 'chart.js/helpers';

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend);

function ChartData() {
  const data = {
    labels: [
      '20 Jan', '21 Jan', '22 Jan', '23 Jan', '24 Jan',
      '25 Jan', '26 Jan', '27 Jan', '28 Jan', '29 Jan', '30 Jan'
    ],
    datasets: [
      {
        label: 'Sólida',
        data: Array.from({ length: 11 }, () => Math.floor(Math.random() * 100)),
        fill: true,
        borderColor: '#6466F1',
        backgroundColor: '#6466F1',
        tension: 0.4,
      },
      {
        label: 'Pontilhada',
        data: Array.from({ length: 11 }, () => Math.floor(Math.random() * 100)),
        borderColor: '#F79008',
        backgroundColor: '#F79008',
        borderDash: [5, 5],
        fill: false,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          color: '#6C737F',
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        ticks: {
          display: false,
        },
        grid: {
          display: true,
          color: '#F6F7F9',
        },
        border: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
    },
  };

  return <Line data={data} options={options} />;
}

export default ChartData;