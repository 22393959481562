import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

// Registrar os componentes necessários no Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

function DonutData() {
  const data = {
    labels: ['Red', 'Blue', 'Yellow', 'Green'],
    datasets: [
      {
        label: '# of Votes',
        data: [7.353, 21.312, 10.242, 8.464],
        backgroundColor: [
          '#F79008',
          '#E5E7EB',
          '#06AED4',
          '#6466F1',
        ],
        borderColor: [
          '#F79008',
          '#E5E7EB',
          '#06AED4',
          '#6466F1',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout: '70%',
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <div class="d-flex flex-column">
      <div style={{ width: '200px', height: '200px', margin: '0 auto 1rem auto' }} >
        <Doughnut data={data} options={options} />
      </div>
      <div class="">
        <div class="d-flex justify-content-between" style={{ padding: '0.8rem 0 0.8rem 0' }}>
          <div class="pl-3" style={{ width: '70%' }}>TOP</div>
          <div style={{ width: '30%' }}>Q</div>
        </div>
        <div class="d-flex justify-content-between align-items-center" style={{ padding: '0.8rem 0 0.8rem 0' }}>
          <div class="pl-3 d-flex justify-content-start align-items-center" style={{ width: '70%' }}>
            <div style={{ width: '7px', height: '7px', borderRadius: '10px', backgroundColor: '#E5E7EB', margin: '0 0.5rem 0 0' }}></div>
            União
          </div>
          <div style={{ width: '30%' }}>1.243</div>
        </div>
        <div class="d-flex justify-content-between" style={{ padding: '0.8rem 0 0.8rem 0' }}>
          <div class="pl-3 d-flex justify-content-start align-items-center" style={{ width: '70%' }}>
            <div style={{ width: '7px', height: '7px', borderRadius: '10px', backgroundColor: '#06AED4', margin: '0 0.5rem 0 0' }}></div>
            União
          </div>
          <div style={{ width: '30%' }}>43</div>
        </div>
        <div class="d-flex justify-content-between" style={{ padding: '0.8rem 0 0.8rem 0' }}>
          <div class="pl-3 d-flex justify-content-start align-items-center" style={{ width: '70%' }}>
            <div style={{ width: '7px', height: '7px', borderRadius: '10px', backgroundColor: '#6466F1', margin: '0 0.5rem 0 0' }}></div>
            União
          </div>
          <div style={{ width: '30%' }}>236</div>
        </div>
        <div class="d-flex justify-content-between" style={{ padding: '0.8rem 0 0.8rem 0' }}>
          <div class="pl-3 d-flex justify-content-start align-items-center" style={{ width: '70%' }}>
            <div style={{ width: '7px', height: '7px', borderRadius: '10px', backgroundColor: '#F79008', margin: '0 0.5rem 0 0' }}></div>
            União
          </div>
          <div style={{ width: '30%' }}>56</div>
        </div>
      </div>
    </div>
  );
}

export default DonutData;