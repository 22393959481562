import { useState } from "react";
import Navbar from "../components/Navbar";
import SideMenu from "../components/SideMenu";
import { BsDownload } from "react-icons/bs";
import { LuArrowRight } from "react-icons/lu";
import { CiSearch } from "react-icons/ci";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

function Datatable() {
    const styles = {
        selectContainer: { position: 'relative', width: '100%' },
        select: { width: '100%', padding: '20px 10px 10px 10px', fontSize: '12px', border: '1px solid #E5E7EB', borderRadius: '4px', outline: 'none', backgroundColor: '#fff' },
        label: { position: 'absolute', top: '10px', left: '10px', color: '#aaa', fontSize: '10px', pointerEvents: 'none', transform: 'translateY(-50%)', transition: '0.2s ease all' },
    };

    const [selectedItems, setSelectedItems] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    //BACKEND COM OS EVENTOS
    const data = [
        { id: 1, title: 'Reunião - Atos Consolidados', date: '21 MAI', month: 'Junho' },
        { id: 1, title: 'Calendário Fiscal PI', date: '21 MAI', month: 'Junho' },
        { id: 1, title: 'Calendário Fiscal RS', date: '21 MAI', month: 'Maio' },
        { id: 1, title: 'Calendário Fiscal RJ', date: '21 MAI', month: 'Maio' },
        { id: 1, title: 'Calendário Fiscal RR', date: '21 MAI', month: 'Maio' }
    ]

    const handleSelect = (id) => {
        if (selectedItems.includes(id)) {
            setSelectedItems(selectedItems.filter(item => item !== id));
        } else {
            setSelectedItems([...selectedItems, id]);
        }
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedItems([]);
        } else {
            const allIds = data.map(item => item.id);
            setSelectedItems(allIds);
        }
        setSelectAll(!selectAll);
    };

    return (
        <div>
            <div className="p-4 d-flex justify-content-between align-items-center" style={{ fontSize: '14px', fontWeight: '600' }}>

                <div className="d-flex align-items-center" style={{ position: 'relative', width: '75%' }}>
                    <CiSearch style={{ position: 'absolute', left: '10px', color: '#232730', fontSize: '24px' }} />
                    <input
                        type="text"
                        placeholder="Procurar"
                        className="input-pesquisa"
                        style={{ padding: '15px 10px 15px 40px' }}
                    />
                </div>

                <div style={{ width: '20%' }}>
                    <div style={styles.selectContainer}>
                        <select id="fullName" name="fullName" defaultValue="Mais recente" style={styles.select}>
                            <option>Mais recente</option>
                        </select>
                        <label htmlFor="fullName" style={styles.label}>
                            Ordernar por
                        </label>
                    </div>
                </div>

            </div>

            <div className="mb-3">
                <div className="d-flex justify-content-between py-3 px-3 bg-light" style={{ fontSize: '13px', fontWeight: '600' }}>
                    <div className="d-flex justify-content-start" style={{ width: '5%' }}>
                        <ul className="d-flex justify-content-start align-items-center" style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                            <li>
                                <label className="d-flex justify-content-start align-items-center" style={{ margin: '0' }}>
                                    <input
                                        type="checkbox"
                                        className="checkbox-input"
                                        checked={selectAll}
                                        onChange={handleSelectAll}
                                    />
                                    <span className="custom_checkbox_library"></span>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div style={{ width: '85%' }}>RELATÓRIOS</div>
                    <div style={{ width: '10%', textAlign: 'end' }}>AÇÕES</div>
                </div>

                {data.map(item => (
                    <div className="d-flex justify-content-between py-3 px-3" key={item.id} style={{ fontSize: '13px', fontWeight: '600', borderBottom: '1px solid #F3F4F7' }}>
                        <div className="d-flex justify-content-start" style={{ width: '5%' }}>
                            <ul className="d-flex justify-content-start align-items-center" style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                <li>
                                    <label className="d-flex justify-content-start align-items-center" style={{ margin: '0' }}>
                                        <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            checked={selectedItems.includes(item.id)}
                                            onChange={() => handleSelect(item.id)}
                                        />
                                        <span className="custom_checkbox_library"></span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex align-items-center" style={{ width: '90%' }}>
                            <div className="mr-3 d-flex flex-column justify-content-center align-items-center" style={{ width: '60px', height: '60px', borderRadius: '15px', backgroundColor: '#E5E7EB' }}>
                                <div style={{ fontSize: '14px', fontWeight: '600' }}>{item.date.split(' ')[1]}</div>
                                <div style={{ fontSize: '15px', fontWeight: '700' }}>{item.date.split(' ')[0]}</div>
                            </div>
                            <div>
                                <div>{item.title}</div>
                                <div>{item.month}</div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{ width: '5%', textAlign: 'end', fontSize: '22px' }}>
                            <div className="mr-3"><BsDownload /></div>
                            <div><LuArrowRight /></div>
                        </div>
                    </div>
                ))}

                <div className="d-flex justify-content-end align-items-center py-2">
                    <div className="mr-4">Documentos por página:</div>
                    <select className="mr-4" style={{ border: 'none' }}>
                        <option>5</option>
                    </select>
                    <div className="mr-4">1-5 de 10</div>
                    <div className="mr-4" style={{ fontSize: '20px' }}><RiArrowLeftSLine /></div>
                    <div className="mr-4" style={{ fontSize: '20px' }}><RiArrowRightSLine /></div>
                </div>
            </div>
        </div>
    );
}

function Apresentacoes() {
    const [activeMenu, setActiveMenu] = useState('Todos');

    // Função para trocar o conteúdo com base no menu clicado
    const renderContent = () => {
        switch (activeMenu) {
            case 'Todos':
                return <Datatable />;
            case '90 dias':
                return <Datatable />;
            case '180 dias':
                return <Datatable />;
            case 'Último ano':
                return <Datatable />;
            default:
                return null;
        }
    };

    return (
        <div id="wrapper" style={{ backgroundColor: '#043A5E' }}>
            <SideMenu />

            <div id="page-wrapper" style={{ backgroundColor: '#FCFCFD' }}>
                <Navbar />

                <div class="pl-2 mt-4 mb-4 custom-normal" style={{ fontSize: '38px', fontWeight: '600' }}>Apresentações Mensais</div>

                <div class="bg-white shadow-sm d-flex flex-column justify-content-between" style={{ borderRadius: '1rem' }}>
                    <div>
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <ul className="nav nav-pills w-100" style={{ borderBottom: '1px solid #F3F4F7' }}>
                                <li className="">
                                    <div
                                        className={`mr-5 ${activeMenu === 'Todos' ? 'active-menu' : ''}`}
                                        style={{ cursor: 'pointer', paddingBottom: '10px' }}
                                        onClick={() => setActiveMenu('Todos')}
                                    >
                                        Todos
                                    </div>
                                </li>
                                <li className="">
                                    <div
                                        className={`mr-5 ${activeMenu === '90 dias' ? 'active-menu' : ''}`}
                                        style={{ cursor: 'pointer', paddingBottom: '10px' }}
                                        onClick={() => setActiveMenu('90 dias')}
                                    >
                                        90 dias
                                    </div>
                                </li>
                                <li className="">
                                    <div
                                        className={`mr-5 ${activeMenu === '180 dias' ? 'active-menu' : ''}`}
                                        style={{ cursor: 'pointer', paddingBottom: '10px' }}
                                        onClick={() => setActiveMenu('180 dias')}
                                    >
                                        180 dias
                                    </div>
                                </li>
                                <li className="">
                                    <div
                                        className={`mr-5 ${activeMenu === 'Último ano' ? 'active-menu' : ''}`}
                                        style={{ cursor: 'pointer', paddingBottom: '10px' }}
                                        onClick={() => setActiveMenu('Último ano')}
                                    >
                                        Último ano
                                    </div>
                                </li>
                            </ul>
                        </nav>

                        <div className="content" style={{ marginTop: '20px' }}>
                            {renderContent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Apresentacoes;
