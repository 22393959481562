import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate} from "react-router-dom";

import { BiFilterAlt } from "react-icons/bi";
import { BsDownload } from "react-icons/bs";
import { LuArrowRight } from "react-icons/lu";
import { CiSearch } from "react-icons/ci";
import { IoMdCalendar } from "react-icons/io";

import SideMenu from '../components/SideMenu';
import Navbar from '../components/Navbar';

function Switch() {
    const [isOn, setIsOn] = useState(false);

    const toggleSwitch = () => {
        setIsOn(!isOn);
    };

    const styles = {
        switchContainer: { display: 'flex', alignItems: 'center', cursor: 'pointer' },
        switch: { width: '50px', height: '16px', backgroundColor: isOn ? '#809DAF' : '#ccc', borderRadius: '25px', position: 'relative', transition: 'background-color 0.3s' },
        switchToggle: { width: '23px', height: '23px', backgroundColor: '#043A5E', borderRadius: '50%', position: 'absolute', top: '-4px', left: isOn ? '26px' : '1px', transition: 'left 0.3s' },
        switchLabel: { marginLeft: '10px', fontSize: '14px' },
    };

    return (
        <div style={styles.switchContainer} onClick={toggleSwitch}>
            <div style={styles.switch}>
                <div style={styles.switchToggle}></div>
            </div>
            <span style={styles.switchLabel}>{isOn ? 'Normas informadas' : 'Normas informadas'}</span>
        </div>
    );
}

function Biblioteca() {
    const navigate = useNavigate();
    const [normasData, setNormasData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selecionados, setSelecionados] = useState([]);
    const token = localStorage.getItem('token');
    const [expandedId, setExpandedId] = useState(null);

    const handleClick = (id) => {
        setExpandedId(expandedId === id ? null : id);
    };

    useEffect(() => {
        const verifyToken = async () => {
            if (!token) {
                navigate('/');
                return;
            }
        };
        verifyToken();
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {

            const additionalSettings = {
                url: "https://cliente-api.taxupdate.com.br/dashboard/normas?data_de=2024-09-10&data_ate=2024-09-10&pagina=0&limite_quantidade=20",
                method: "GET",
                timeout: 0,
                headers: {
                    "Authorization": "Bearer " + token
                }
            };

            try {
                const normasResponse = await axios(additionalSettings);
                setNormasData(normasResponse.data);
            } catch (error) {
                setError(error);
            }

            setLoading(false);
        };

        fetchData();
    }, []);

    const formatDate = (dateString) => {
        const [datePart] = dateString.split(' ');
        const [year, month, day] = datePart.split('-');
        return `${day}/${month}/${year}`;
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    // Filtros preparados para receberem valores do backend, só aplicar igual estados
    const estados = [
        'Acre',
        'Alagoas',
        'Amapá',
        'Amazonas',
        'Bahia',
        'Ceará',
        'Distrito Federal',
        'Espírito Santo',
        'Goiás',
        'Maranhão',
    ];

    const handleCheckboxChange = (estado) => {
        if (selecionados.includes(estado)) {
            setSelecionados(selecionados.filter((item) => item !== estado));
        } else {
            setSelecionados([...selecionados, estado]);
        }
    };

    const NormaLabel = ({ areaAtuacaoNome }) => {
        const renderLabel = () => {
            if (areaAtuacaoNome === "UNIÃO") {
                return (
                    <div className="py-1 text-center" style={{
                        backgroundColor: '#E2F7F1',
                        color: '#08815B',
                        borderRadius: '1rem',
                        fontWeight: '600',
                        width: '85px',
                        letterSpacing: '0.7px'
                    }}>FEDERAL</div>
                );
            } else if (areaAtuacaoNome.length === 2) {
                return (
                    <div className="py-1 text-center" style={{
                        backgroundColor: '#E2EBF7',
                        color: '#043A5F',
                        borderRadius: '1rem',
                        fontWeight: '600',
                        width: '85px',
                        letterSpacing: '0.5px'
                    }}>ESTADUAL</div>
                );
            } else if (areaAtuacaoNome.length > 4) {
                return (
                    <div className="py-1 text-center" style={{
                        backgroundColor: '#F6E9E1',
                        color: '#E9742E',
                        borderRadius: '1rem',
                        fontWeight: '600',
                        width: '85px'
                    }}>MUNICIPAL</div>
                );
            }
            return null;
        };
        return renderLabel();
    };

    return (
        <div id="wrapper" style={{backgroundColor: '#043A5E'}}>
            <SideMenu/>

            <div id="page-wrapper" style={{backgroundColor: '#FCFCFD'}}>
                <Navbar/>

                <div className="wrapper wrapper-content">
                    <div className="d-flex justify-content-between">

                        {/* FILTROS */}
                        <div className="border-0 shadow-sm bg-white p-4" style={{borderRadius: '1rem', width: '25%'}}>
                            <div className="">
                                <div className="mb-4 custom-normal"
                                     style={{fontSize: '25px', fontWeight: '600'}}>Filtros
                                </div>
                                <div className="">
                                    <div className="d-flex align-items-center" style={{position: 'relative'}}>
                                        <CiSearch style={{
                                            position: 'absolute',
                                            left: '10px',
                                            color: '#232730',
                                            fontSize: '24px'
                                        }}/>
                                        <input
                                            type="text"
                                            placeholder="Palavras Chave"
                                            className="input-pesquisa"
                                        />
                                    </div>

                                    {/* ON e OFF (FUNCIONAL) */}
                                    <div className="mb-4 mt-4"><Switch/></div>

                                    <div className={"form"}>
                                        <label className="control-label mt-2">Data de Publicação</label>
                                        <div className="">
                                            <div style={{position: 'relative'}}>
                                                <input type="text" placeholder="De"
                                                       style={{
                                                           width: '100%',
                                                           padding: '13px',
                                                           paddingRight: '35px',
                                                           border: '1px solid #E5E7EB',
                                                           borderRadius: '7px',
                                                           fontSize: '14px'
                                                       }}
                                                />
                                                <IoMdCalendar style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    fontSize: '20px'
                                                }}/>
                                            </div>

                                            <div className="mt-3" style={{position: 'relative'}}>
                                                <input type="text" placeholder="Até"
                                                       style={{
                                                           width: '100%',
                                                           padding: '13px',
                                                           paddingRight: '35px',
                                                           border: '1px solid #E5E7EB',
                                                           borderRadius: '7px',
                                                           fontSize: '14px'
                                                       }}
                                                />
                                                <IoMdCalendar style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    fontSize: '20px'
                                                }}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <div className="mb-2">Estado</div>
                                        <div className="custom-container-roll" style={{
                                            height: '190px',
                                            overflowY: 'auto',
                                            backgroundColor: '#F8F9FA',
                                            borderRadius: '7px',
                                            padding: '10px'
                                        }}>
                                            <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
                                                {estados.map((estado) => (
                                                    <li key={estado} style={{marginBottom: '18px'}}>
                                                        <label
                                                            className="d-flex justify-content-start align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                checked={selecionados.includes(estado)}
                                                                onChange={() => handleCheckboxChange(estado)}
                                                                className="checkbox-input"
                                                            />
                                                            <span className="custom_checkbox_library"></span>
                                                            <div style={{fontSize: '15px'}}>{estado}</div>
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <div className="mb-2">Municípios</div>
                                        <div className="custom-container-roll" style={{
                                            height: '190px',
                                            overflowY: 'auto',
                                            backgroundColor: '#F8F9FA',
                                            borderRadius: '7px',
                                            padding: '10px'
                                        }}>
                                            <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
                                                {estados.map((estado) => (
                                                    <li key={estado} style={{marginBottom: '18px'}}>
                                                        <label
                                                            className="d-flex justify-content-start align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                checked={selecionados.includes(estado)}
                                                                onChange={() => handleCheckboxChange(estado)}
                                                                className="checkbox-input"
                                                            />
                                                            <span className="custom_checkbox_library"></span>
                                                            <div style={{fontSize: '15px'}}>{estado}</div>
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <div className="mb-2">Tipo de Impostos</div>
                                        <div className="custom-container-roll" style={{
                                            height: '190px',
                                            overflowY: 'auto',
                                            backgroundColor: '#F8F9FA',
                                            borderRadius: '7px',
                                            padding: '10px'
                                        }}>
                                            <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
                                                {estados.map((estado) => (
                                                    <li key={estado} style={{marginBottom: '18px'}}>
                                                        <label
                                                            className="d-flex justify-content-start align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                checked={selecionados.includes(estado)}
                                                                onChange={() => handleCheckboxChange(estado)}
                                                                className="checkbox-input"
                                                            />
                                                            <span className="custom_checkbox_library"></span>
                                                            <div style={{fontSize: '15px'}}>{estado}</div>
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="mt-4">
                                        <div className="mb-2">Área de Atuação</div>
                                        <div className="custom-container-roll" style={{
                                            height: '190px',
                                            overflowY: 'auto',
                                            backgroundColor: '#F8F9FA',
                                            borderRadius: '7px',
                                            padding: '10px'
                                        }}>
                                            <ul style={{listStyleType: 'none', padding: 0, margin: 0}}>
                                                {estados.map((estado) => (
                                                    <li key={estado} style={{marginBottom: '18px'}}>
                                                        <label
                                                            className="d-flex justify-content-start align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                checked={selecionados.includes(estado)}
                                                                onChange={() => handleCheckboxChange(estado)}
                                                                className="checkbox-input"
                                                            />
                                                            <span className="custom_checkbox_library"></span>
                                                            <div style={{fontSize: '15px'}}>{estado}</div>
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* TABELA */}
                        <div className="" style={{width: '73%'}}>
                            <div className="w-100">
                                <div className="d-flex justify-content-between align-items-center mt-4 mb-4 w-100">
                                    <div className="pl-4 custom-normal"
                                         style={{fontSize: '34px', fontWeight: '600'}}>Biblioteca de Normas
                                    </div>
                                    {/*<div className="d-flex" style={{fontSize: '18px', fontWeight: '600'}}>*/}
                                    {/*    <div><BiFilterAlt/></div>*/}
                                    {/*    <div className="ml-2 custom-normal">Filtros</div>*/}
                                    {/*</div>*/}
                                </div>

                                    <div className="bg-white" style={{borderRadius: '1rem'}}>
                                        {/* UNIQUE CARD */}
                                        {normasData.normas ? normasData.normas.map((norma) => (
                                            <div className="d-flex flex-column align-items-end">
                                                <div
                                                    className="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                                    <div className="" style={{width: '45%'}}>
                                                        <div className="custom-normal"
                                                             style={{fontSize: '14px', fontWeight: '600'}}>
                                                            {norma.nome}
                                                        </div>
                                                        <div
                                                            className="font-weight-light"
                                                            onClick={() => handleClick(norma.id)}
                                                            style={{
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                display: '-webkit-box',
                                                                WebkitBoxOrient: 'vertical',
                                                                WebkitLineClamp: expandedId === norma.id ? 'unset' : 3,
                                                                maxHeight: expandedId === norma.id ? 'none' : '4.5em',
                                                                cursor: 'pointer',
                                                                transition: 'max-height 0.3s ease, padding-bottom 0.3s ease',
                                                                paddingBottom: expandedId === norma.id ? '2rem' : '1rem'
                                                            }}
                                                        >
                                                            {norma.descricao}
                                                        </div>
                                                    </div>
                                                    <div className="font-weight-light">{formatDate(norma.criado)}</div>

                                                    <NormaLabel areaAtuacaoNome={norma.areaatuacao_nome}/>

                                                    <div className="d-flex justify-content-between align-items-center"
                                                         style={{fontSize: '22px'}}>
                                                        <div className="mr-3"><BsDownload/></div>
                                                        <div><LuArrowRight/></div>
                                                    </div>
                                                </div>
                                                <div className="border-bottom" style={{width: '97%'}}></div>
                                            </div>
                                        )) : (
                                            <div></div>
                                        )}
                                        {/* UNIQUE CARD */}
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Biblioteca;