import React, { useState } from 'react';

function Switch() {
    const [isOn, setIsOn] = useState(false);

    const toggleSwitch = () => {
        setIsOn(!isOn);
    };

    const styles = {
        switchContainer: { display: 'flex', alignItems: 'center', cursor: 'pointer' },
        switch: { width: '40px', height: '16px', backgroundColor: isOn ? '#B1B3F8' : '#ccc', borderRadius: '25px', position: 'relative', transition: 'background-color 0.3s' },
        switchToggle: { width: '20px', height: '20px', backgroundColor: isOn ? '#6266F0' : '#6C737F', borderRadius: '50%', position: 'absolute', top: '-2px', left: isOn ? '20px' : '0px', transition: 'left 0.3s' },
        switchLabel: { marginLeft: '10px', fontSize: '14px' },
    };

    return (
        <div style={styles.switchContainer} onClick={toggleSwitch}>
            <div style={styles.switch}>
                <div style={styles.switchToggle}></div>
            </div>
            <span style={styles.switchLabel}></span>
        </div>
    );
}

function Notificacao() {
    return (
        <div style={{ borderRadius: '1rem' }}>
            <div class="bg-white shadow-sm d-flex justify-content-between" style={{ borderBottom: '1px solid #F3F4F7' }}>
                <div className="p-4" style={{ fontSize: '14px', fontWeight: '600', width: '50%', color: '#111927' }}>Email</div>
                <div className='p-4' style={{ width: '50%', borderBottom: '1px solid #F3F4F7' }}>
                    <div className='d-flex justify-content-between align-items-center pb-3' >
                        <div>
                            <div className='mb-2' style={{ fontSize: '14px', fontWeight: '500', color: '#101927' }}>Reports</div>
                            <div style={{ fontSize: '12px', fontWeight: '400', color: '#6C737F' }}>Novos reports publicados</div>
                        </div>
                        <div><Switch /></div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mt-3'>
                        <div>
                            <div className='mb-2' style={{ fontSize: '14px', fontWeight: '500', color: '#101927' }}>Suporte & Segurança</div>
                            <div style={{ fontSize: '12px', fontWeight: '400', color: '#6C737F' }}>Notificações de respostas a tickets de suporte e atualizaçÕes de segurança</div>
                        </div>
                        <div><Switch /></div>
                    </div>
                </div>
            </div>
            <div class="bg-white shadow-sm d-flex justify-content-between">
                <div className="p-4" style={{ fontSize: '14px', fontWeight: '600', width: '50%', color: '#111927' }}>Push</div>
                <div className='p-4' style={{ width: '50%', borderBottom: '1px solid #F3F4F7' }}>
                    <div className='d-flex justify-content-between align-items-center pb-3' style={{ borderBottom: '1px solid #F3F4F7' }}>
                        <div>
                            <div className='mb-2' style={{ fontSize: '14px', fontWeight: '500', color: '#101927' }}>Reports</div>
                            <div style={{ fontSize: '12px', fontWeight: '400', color: '#6C737F' }}>Novos reports publicados</div>
                        </div>
                        <div><Switch /></div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center mt-3'>
                        <div>
                            <div className='mb-2' style={{ fontSize: '14px', fontWeight: '500', color: '#101927' }}>Suporte & Segurança</div>
                            <div style={{ fontSize: '12px', fontWeight: '400', color: '#6C737F' }}>Notificações de respostas a tickets de suporte e atualizaçÕes de segurança</div>
                        </div>
                        <div><Switch /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notificacao;
