import React, { useState } from 'react';

import { BiFilterAlt } from "react-icons/bi";
import { BsDownload } from "react-icons/bs";
import { LuArrowRight } from "react-icons/lu";
import { CiSearch } from "react-icons/ci";
import { IoMdCalendar } from "react-icons/io";

import SideMenu from '../components/SideMenu';
import Navbar from '../components/Navbar';

function Switch() {
    const [isOn, setIsOn] = useState(false);

    const toggleSwitch = () => {
        setIsOn(!isOn);
    };

    const styles = {
        switchContainer: { display: 'flex', alignItems: 'center', cursor: 'pointer' },
        switch: { width: '50px', height: '16px', backgroundColor: isOn ? '#809DAF' : '#ccc', borderRadius: '25px', position: 'relative', transition: 'background-color 0.3s' },
        switchToggle: { width: '23px', height: '23px', backgroundColor: '#043A5E', borderRadius: '50%', position: 'absolute', top: '-4px', left: isOn ? '26px' : '1px', transition: 'left 0.3s' },
        switchLabel: { marginLeft: '10px', fontSize: '14px' },
    };

    return (
        <div style={styles.switchContainer} onClick={toggleSwitch}>
            <div style={styles.switch}>
                <div style={styles.switchToggle}></div>
            </div>
            <span style={styles.switchLabel}>{isOn ? 'Normas informadas' : 'Normas informadas'}</span>
        </div>
    );
}

function LeisTraduzidas() {
    // Filtros preparados para receberem valores do backend, só aplicar igual estados
    const estados = [
        'Acre',
        'Alagoas',
        'Amapá',
        'Amazonas',
        'Bahia',
        'Ceará',
        'Distrito Federal',
        'Espírito Santo',
        'Goiás',
        'Maranhão',
    ];

    const [selecionados, setSelecionados] = useState([]);

    const handleCheckboxChange = (estado) => {
        if (selecionados.includes(estado)) {
            setSelecionados(selecionados.filter((item) => item !== estado));
        } else {
            setSelecionados([...selecionados, estado]);
        }
    };

    return (
        <div id="wrapper" style={{ backgroundColor: '#043A5E' }}>
            <SideMenu />

            <div id="page-wrapper" style={{ backgroundColor: '#FCFCFD' }}>
                <Navbar />

                <div class="wrapper wrapper-content">
                    <div class="d-flex justify-content-between">

                        {/* FILTROS */}
                        <div class="border-0 shadow-sm bg-white p-4" style={{ borderRadius: '1rem', width: '25%' }}>
                            <div class="">
                                <div class="mb-4 custom-normal" style={{ fontSize: '25px', fontWeight: '600' }}>Filtros</div>
                                <div class="">
                                    <div className="d-flex align-items-center" style={{ position: 'relative' }}>
                                        <CiSearch style={{ position: 'absolute', left: '10px', color: '#232730', fontSize: '24px' }} />
                                        <input
                                            type="text"
                                            placeholder="Palavras Chave"
                                            className="input-pesquisa"
                                        />
                                    </div>

                                    <div class={"form"}>
                                        <label class="control-label mt-2">Data de Publicação</label>
                                        <div class="">
                                            <div style={{ position: 'relative' }}>
                                                <input type="text" placeholder="De"
                                                       style={{ width: '100%', padding: '13px', paddingRight: '35px', border: '1px solid #E5E7EB', borderRadius: '7px', fontSize: '14px' }}
                                                />
                                                <IoMdCalendar style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', fontSize: '20px' }} />
                                            </div>

                                            <div class="mt-3" style={{ position: 'relative' }}>
                                                <input type="text" placeholder="Até"
                                                       style={{ width: '100%', padding: '13px', paddingRight: '35px', border: '1px solid #E5E7EB', borderRadius: '7px', fontSize: '14px' }}
                                                />
                                                <IoMdCalendar style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', fontSize: '20px' }} />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-4">
                                        <div class="mb-2">Estado</div>
                                        <div class="custom-container-roll" style={{ height: '190px', overflowY: 'auto', backgroundColor: '#F8F9FA', borderRadius: '7px', padding: '10px' }}>
                                            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                                {estados.map((estado) => (
                                                    <li key={estado} style={{ marginBottom: '18px' }}>
                                                        <label class="d-flex justify-content-start align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                checked={selecionados.includes(estado)}
                                                                onChange={() => handleCheckboxChange(estado)}
                                                                class="checkbox-input"
                                                            />
                                                            <span class="custom_checkbox_library"></span>
                                                            <div style={{ fontSize: '15px' }}>{estado}</div>
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="mt-4">
                                        <div class="mb-2">Municípios</div>
                                        <div class="custom-container-roll" style={{ height: '190px', overflowY: 'auto', backgroundColor: '#F8F9FA', borderRadius: '7px', padding: '10px' }}>
                                            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                                {estados.map((estado) => (
                                                    <li key={estado} style={{ marginBottom: '18px' }}>
                                                        <label class="d-flex justify-content-start align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                checked={selecionados.includes(estado)}
                                                                onChange={() => handleCheckboxChange(estado)}
                                                                class="checkbox-input"
                                                            />
                                                            <span class="custom_checkbox_library"></span>
                                                            <div style={{ fontSize: '15px' }}>{estado}</div>
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="mt-4">
                                        <div class="mb-2">Tipo de Impostos</div>
                                        <div class="custom-container-roll" style={{ height: '190px', overflowY: 'auto', backgroundColor: '#F8F9FA', borderRadius: '7px', padding: '10px' }}>
                                            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                                {estados.map((estado) => (
                                                    <li key={estado} style={{ marginBottom: '18px' }}>
                                                        <label class="d-flex justify-content-start align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                checked={selecionados.includes(estado)}
                                                                onChange={() => handleCheckboxChange(estado)}
                                                                class="checkbox-input"
                                                            />
                                                            <span class="custom_checkbox_library"></span>
                                                            <div style={{ fontSize: '15px' }}>{estado}</div>
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="mt-4">
                                        <div class="mb-2">Área de Atuação</div>
                                        <div class="custom-container-roll" style={{ height: '190px', overflowY: 'auto', backgroundColor: '#F8F9FA', borderRadius: '7px', padding: '10px' }}>
                                            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                                {estados.map((estado) => (
                                                    <li key={estado} style={{ marginBottom: '18px' }}>
                                                        <label class="d-flex justify-content-start align-items-center">
                                                            <input
                                                                type="checkbox"
                                                                checked={selecionados.includes(estado)}
                                                                onChange={() => handleCheckboxChange(estado)}
                                                                class="checkbox-input"
                                                            />
                                                            <span class="custom_checkbox_library"></span>
                                                            <div style={{ fontSize: '15px' }}>{estado}</div>
                                                        </label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    {/* ON e OFF (FUNCIONAL) */}
                                    <div class="mb-4 mt-4"><Switch /></div>
                                </div>
                            </div>
                        </div>

                        {/* TABELA */}
                        <div class="" style={{ width: '73%' }}>
                            <div class="w-100">
                                <div class="d-flex justify-content-between align-items-center mt-4 mb-4 w-100">
                                    <div class="pl-4 custom-normal" style={{ fontSize: '34px', fontWeight: '600' }}>Leis Traduzidas</div>
                                    <div class="d-flex" style={{ fontSize: '18px', fontWeight: '600' }}>
                                        <div><BiFilterAlt /></div>
                                        <div class="ml-2 custom-normal">Filtros</div>
                                    </div>
                                </div>

                                <div class="bg-white" style={{ borderRadius: '1rem' }}>
                                    {/* UNIQUE CARD */}
                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                            <div class="" style={{ width: '45%' }}>
                                                <div class="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>Ato Declaratório Executivo em Sorocaba nº 304</div>
                                                <div class="font-weight-light">DOU</div>
                                            </div>
                                            <div class="font-weight-light">01/02/2024</div>
                                            <div className='d-flex justify-content-start align-items-center' style={{ width: '20%' }}>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#FEF9E2', color: '#7A630A', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>PT</div>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>ES</div>
                                                <div class="py-1 text-center" style={{ backgroundColor: '#E1EBF3', color: '#5D8FB7', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>EN</div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                <div><LuArrowRight /></div>
                                            </div>
                                        </div>
                                        <div class="border-bottom" style={{ width: '99%' }}></div>
                                    </div>
                                    {/* UNIQUE CARD */}

                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                            <div class="" style={{ width: '45%' }}>
                                                <div class="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>Decreto Estadual nº 11.423</div>
                                                <div class="font-weight-light">AC DOE</div>
                                            </div>
                                            <div class="font-weight-light">01/02/2024</div>
                                            <div className='d-flex justify-content-start align-items-center' style={{ width: '20%' }}>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#FEF9E2', color: '#7A630A', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>PT</div>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>ES</div>
                                                <div class="py-1 text-center" style={{ backgroundColor: '#E1EBF3', color: '#5D8FB7', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>EN</div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                <div><LuArrowRight /></div>
                                            </div>
                                        </div>
                                        <div class="border-bottom" style={{ width: '97%' }}></div>
                                    </div>
                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                            <div class="" style={{ width: '45%' }}>
                                                <div class="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>Decreto Municipal nº 45.933</div>
                                                <div class="font-weight-light">Aracruz (ES)</div>
                                            </div>
                                            <div class="font-weight-light">01/02/2024</div>
                                            <div className='d-flex justify-content-start align-items-center' style={{ width: '20%' }}>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#FEF9E2', color: '#7A630A', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>PT</div>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>ES</div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                <div><LuArrowRight /></div>
                                            </div>
                                        </div>
                                        <div class="border-bottom" style={{ width: '97%' }}></div>
                                    </div>
                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                            <div class="" style={{ width: '45%' }}>
                                                <div class="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>Ato Declaratório Executivo em Sorocaba nº 304</div>
                                                <div class="font-weight-light">DOU</div>
                                            </div>
                                            <div class="font-weight-light">01/02/2024</div>
                                            <div className='d-flex justify-content-start align-items-center' style={{ width: '20%' }}>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#FEF9E2', color: '#7A630A', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>PT</div>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>ES</div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                <div><LuArrowRight /></div>
                                            </div>
                                        </div>
                                        <div class="border-bottom" style={{ width: '97%' }}></div>
                                    </div>
                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                            <div class="" style={{ width: '45%' }}>
                                                <div class="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>Decreto Estadual nº 11.423</div>
                                                <div class="font-weight-light">AC DOE</div>
                                            </div>
                                            <div class="font-weight-light">01/02/2024</div>
                                            <div className='d-flex justify-content-start align-items-center' style={{ width: '20%' }}>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#FEF9E2', color: '#7A630A', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>PT</div>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>ES</div>
                                                <div class="py-1 text-center" style={{ backgroundColor: '#E1EBF3', color: '#5D8FB7', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>EN</div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                <div><LuArrowRight /></div>
                                            </div>
                                        </div>
                                        <div class="border-bottom" style={{ width: '97%' }}></div>
                                    </div>
                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                            <div class="" style={{ width: '45%' }}>
                                                <div class="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>Decreto Municipal nº 45.933</div>
                                                <div class="font-weight-light">Aracruz (ES)</div>
                                            </div>
                                            <div class="font-weight-light">01/02/2024</div>
                                            <div className='d-flex justify-content-start align-items-center' style={{ width: '20%' }}>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#FEF9E2', color: '#7A630A', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>PT</div>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>ES</div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                <div><LuArrowRight /></div>
                                            </div>
                                        </div>
                                        <div class="border-bottom" style={{ width: '97%' }}></div>
                                    </div>
                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                            <div class="" style={{ width: '45%' }}>
                                                <div class="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>Ato Declaratório Executivo em Sorocaba nº 304</div>
                                                <div class="font-weight-light">DOU</div>
                                            </div>
                                            <div class="font-weight-light">01/02/2024</div>
                                            <div className='d-flex justify-content-start align-items-center' style={{ width: '20%' }}>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#FEF9E2', color: '#7A630A', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>PT</div>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>ES</div>
                                                <div class="py-1 text-center" style={{ backgroundColor: '#E1EBF3', color: '#5D8FB7', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>EN</div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                <div><LuArrowRight /></div>
                                            </div>
                                        </div>
                                        <div class="border-bottom" style={{ width: '97%' }}></div>
                                    </div>
                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                            <div class="" style={{ width: '45%' }}>
                                                <div class="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>Decreto Estadual nº 11.423</div>
                                                <div class="font-weight-light">AC DOE</div>
                                            </div>
                                            <div class="font-weight-light">01/02/2024</div>
                                            <div className='d-flex justify-content-start align-items-center' style={{ width: '20%' }}>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#FEF9E2', color: '#7A630A', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>PT</div>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>ES</div>
                                                <div class="py-1 text-center" style={{ backgroundColor: '#E1EBF3', color: '#5D8FB7', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>EN</div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                <div><LuArrowRight /></div>
                                            </div>
                                        </div>
                                        <div class="border-bottom" style={{ width: '97%' }}></div>
                                    </div>
                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                            <div class="" style={{ width: '45%' }}>
                                                <div class="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>Decreto Municipal nº 45.933</div>
                                                <div class="font-weight-light">Aracruz (ES)</div>
                                            </div>
                                            <div class="font-weight-light">01/02/2024</div>
                                            <div className='d-flex justify-content-start align-items-center' style={{ width: '20%' }}>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#FEF9E2', color: '#7A630A', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>PT</div>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>ES</div>
                                                <div class="py-1 text-center" style={{ backgroundColor: '#E1EBF3', color: '#5D8FB7', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>EN</div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                <div><LuArrowRight /></div>
                                            </div>
                                        </div>
                                        <div class="border-bottom" style={{ width: '97%' }}></div>
                                    </div>
                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                            <div class="" style={{ width: '45%' }}>
                                                <div class="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>Ato Declaratório Executivo em Sorocaba nº 304</div>
                                                <div class="font-weight-light">DOU</div>
                                            </div>
                                            <div class="font-weight-light">01/02/2024</div>
                                            <div className='d-flex justify-content-start align-items-center' style={{ width: '20%' }}>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#FEF9E2', color: '#7A630A', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>PT</div>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>ES</div>
                                                <div class="py-1 text-center" style={{ backgroundColor: '#E1EBF3', color: '#5D8FB7', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>EN</div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                <div><LuArrowRight /></div>
                                            </div>
                                        </div>
                                        <div class="border-bottom" style={{ width: '97%' }}></div>
                                    </div>
                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                            <div class="" style={{ width: '45%' }}>
                                                <div class="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>Decreto Estadual nº 11.423</div>
                                                <div class="font-weight-light">AC DOE</div>
                                            </div>
                                            <div class="font-weight-light">01/02/2024</div>
                                            <div className='d-flex justify-content-start align-items-center' style={{ width: '20%' }}>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#FEF9E2', color: '#7A630A', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>PT</div>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>ES</div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                <div><LuArrowRight /></div>
                                            </div>
                                        </div>
                                        <div class="border-bottom" style={{ width: '97%' }}></div>
                                    </div>
                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                            <div class="" style={{ width: '45%' }}>
                                                <div class="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>Decreto Municipal nº 45.933</div>
                                                <div class="font-weight-light">Aracruz (ES)</div>
                                            </div>
                                            <div class="font-weight-light">01/02/2024</div>
                                            <div className='d-flex justify-content-start align-items-center' style={{ width: '20%' }}>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#FEF9E2', color: '#7A630A', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>PT</div>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>ES</div>
                                                <div class="py-1 text-center" style={{ backgroundColor: '#E1EBF3', color: '#5D8FB7', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>EN</div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                <div><LuArrowRight /></div>
                                            </div>
                                        </div>
                                        <div class="border-bottom" style={{ width: '97%' }}></div>
                                    </div>
                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                            <div class="" style={{ width: '45%' }}>
                                                <div class="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>Ato Declaratório Executivo em Sorocaba nº 304</div>
                                                <div class="font-weight-light">DOU</div>
                                            </div>
                                            <div class="font-weight-light">01/02/2024</div>
                                            <div className='d-flex justify-content-start align-items-center' style={{ width: '20%' }}>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#FEF9E2', color: '#7A630A', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>PT</div>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>ES</div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                <div><LuArrowRight /></div>
                                            </div>
                                        </div>
                                        <div class="border-bottom" style={{ width: '97%' }}></div>
                                    </div>
                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                            <div class="" style={{ width: '45%' }}>
                                                <div class="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>Decreto Estadual nº 11.423</div>
                                                <div class="font-weight-light">AC DOE</div>
                                            </div>
                                            <div class="font-weight-light">01/02/2024</div>
                                            <div className='d-flex justify-content-start align-items-center' style={{ width: '20%' }}>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#FEF9E2', color: '#7A630A', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>PT</div>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>ES</div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                <div><LuArrowRight /></div>
                                            </div>
                                        </div>
                                        <div class="border-bottom" style={{ width: '97%' }}></div>
                                    </div>
                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                            <div class="" style={{ width: '45%' }}>
                                                <div class="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>Decreto Municipal nº 45.933</div>
                                                <div class="font-weight-light">Aracruz (ES)</div>
                                            </div>
                                            <div class="font-weight-light">01/02/2024</div>
                                            <div className='d-flex justify-content-start align-items-center' style={{ width: '20%' }}>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#FEF9E2', color: '#7A630A', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>PT</div>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>ES</div>
                                                <div class="py-1 text-center" style={{ backgroundColor: '#E1EBF3', color: '#5D8FB7', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>EN</div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                <div><LuArrowRight /></div>
                                            </div>
                                        </div>
                                        <div class="border-bottom" style={{ width: '97%' }}></div>
                                    </div>
                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                            <div class="" style={{ width: '45%' }}>
                                                <div class="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>Ato Declaratório Executivo em Sorocaba nº 304</div>
                                                <div class="font-weight-light">DOU</div>
                                            </div>
                                            <div class="font-weight-light">01/02/2024</div>
                                            <div className='d-flex justify-content-start align-items-center' style={{ width: '20%' }}>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#FEF9E2', color: '#7A630A', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>PT</div>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>ES</div>
                                                <div class="py-1 text-center" style={{ backgroundColor: '#E1EBF3', color: '#5D8FB7', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>EN</div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                <div><LuArrowRight /></div>
                                            </div>
                                        </div>
                                        <div class="border-bottom" style={{ width: '97%' }}></div>
                                    </div>
                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                            <div class="" style={{ width: '45%' }}>
                                                <div class="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>Decreto Estadual nº 11.423</div>
                                                <div class="font-weight-light">AC DOE</div>
                                            </div>
                                            <div class="font-weight-light">01/02/2024</div>
                                            <div className='d-flex justify-content-start align-items-center' style={{ width: '20%' }}>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#FEF9E2', color: '#7A630A', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>PT</div>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>ES</div>
                                                <div class="py-1 text-center" style={{ backgroundColor: '#E1EBF3', color: '#5D8FB7', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>EN</div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                <div><LuArrowRight /></div>
                                            </div>
                                        </div>
                                        <div class="border-bottom" style={{ width: '97%' }}></div>
                                    </div>
                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex justify-content-between align-items-center px-3 py-3 w-100">
                                            <div class="" style={{ width: '45%' }}>
                                                <div class="custom-normal" style={{ fontSize: '14px', fontWeight: '600' }}>Decreto Municipal nº 45.933</div>
                                                <div class="font-weight-light">Aracruz (ES)</div>
                                            </div>
                                            <div class="font-weight-light">01/02/2024</div>
                                            <div className='d-flex justify-content-start align-items-center' style={{ width: '20%' }}>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#FEF9E2', color: '#7A630A', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>PT</div>
                                                <div class="py-1 text-center mr-3" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>ES</div>
                                                <div class="py-1 text-center" style={{ backgroundColor: '#E1EBF3', color: '#5D8FB7', borderRadius: '1rem', fontWeight: '600', width: '40px', letterSpacing: '0.7px' }}>EN</div>
                                            </div>
                                            <div class="d-flex justify-content-between align-items-center" style={{ fontSize: '22px' }}>
                                                <div><LuArrowRight /></div>
                                            </div>
                                        </div>
                                        <div class="border-bottom" style={{ width: '97%' }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
export default LeisTraduzidas;