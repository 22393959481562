import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {AiOutlineExclamationCircle} from "react-icons/ai";
import {IoIosArrowRoundForward} from "react-icons/io";
import {GoArrowSwitch} from "react-icons/go";
import {useNavigate} from "react-router-dom";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import SideMenu from '../components/SideMenu';
import Navbar from "../components/Navbar";

import grafico1 from '../img/dashboard/graf1.png';
import grafico2 from '../img/dashboard/graf2.png';
import grafico3 from '../img/dashboard/graf3.png';
import city from '../img/dashboard/city.png';

dayjs.extend(utc);

function Dashboard() {
    const navigate = useNavigate();
    const [staticDatas, setStaticDatas] = useState(null);
    const [normasData, setNormasData] = useState(null);
    const [calendarData, setCalendarData] = useState(null);
    const [postsData, setPostsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const token = localStorage.getItem('token');
    const [valorBid, setValorBid] = useState('');
    const [valor, setValor] = useState(100);
    const [de, setDe] = useState('USD');
    const [para, setPara] = useState('BRL');
    const [createDate, setCreateDate] = useState('');

    useEffect(() => {
        const verifyToken = async () => {
            if (!token) {
                navigate('/');
                return;
            }
        };
        verifyToken();
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            const dataAtual = dayjs().format('YYYY-MM-DD')
            const mesAtual = dayjs().format('MM-DD')

            const settings = {
                url: "https://cliente-api.taxupdate.com.br/dashboard/dados-estatisticos",
                method: "GET",
                timeout: 0,
                headers: {
                    "Authorization": "Bearer " + token
                }
            };

            try {
                const response = await axios(settings);
                setStaticDatas(response.data);
            } catch (error) {
                setError(error);
            }

            const additionalSettings = {
                url: `https://cliente-api.taxupdate.com.br/dashboard/normas?data_de=${dataAtual}&data_ate=${dataAtual}&pagina=0&limite_quantidade=5`,
                method: "GET",
                timeout: 0,
                headers: {
                    "Authorization": "Bearer " + token
                }
            };

            try {
                const normasResponse = await axios(additionalSettings);
                setNormasData(normasResponse.data);
            } catch (error) {
                setError(error);
            }

            const calendarSettings = {
                url: `https://cliente-api.taxupdate.com.br/calendario?mes=${mesAtual}&limite_page=6`,
                method: "GET",
                timeout: 0,
                headers: {
                    "Authorization": "Bearer " + token
                }
            };

            try {
                const calendarResponse = await axios(calendarSettings);
                setCalendarData(calendarResponse.data);
            } catch (error) {
                setError(error);
            }

            setLoading(false);

            const postsSettings = {
                url: "https://cliente-api.taxupdate.com.br/dashboard/posts?limite_quantidade=3",
                method: "GET",
                timeout: 0,
                headers: {
                    "Authorization": "Bearer " + token
                }
            };

            try {
                const postsResponse = await axios(postsSettings);
                setPostsData(postsResponse.data);
            } catch (error) {
                setError(error);
            }

            setLoading(false);

        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://cliente-api.taxupdate.com.br/conversor/${valor}/${de}/${para}`, {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                });
                setValorBid(response.data.valor_bid);
                setCreateDate(response.data.create_date);
            } catch (error) {
                setValorBid(0.00);
                console.error('Erro ao buscar os dados: ', error);
            }
        };

        fetchData();
    }, [valor, de, para]);

    const formatDate = (dateString) => {
        const [datePart] = dateString.split(' ');
        const [year, month, day] = datePart.split('-');
        return `${day}/${month}/${year}`;
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div id="wrapper" style={{backgroundColor: '#043A5E'}}>
            <SideMenu/>

            <div id="page-wrapper" style={{backgroundColor: '#FCFCFD'}}>
                <Navbar/>

                <div className="pl-4 mt-4" style={{fontSize: '38px', fontWeight: '600'}}>Dashboard TaxUpdate 2024</div>

                <div className="wrapper wrapper-content">
                    <div className="d-flex justify-content-between pl-4 pr-5">
                        <div className="">
                            <h3>Dados Estatísticos</h3>
                        </div>
                        <div className="">
                            <h3>Próxima Data</h3>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between mb-4">
                        <div className="" style={{width: '25%'}}>
                            <div
                                className="p-4 bg-white border-0 shadow-sm text-center d-flex justify-content-between align-items-center"
                                style={{borderRadius: '1rem'}}>
                                <div className="d-flex flex-column align-items-start">
                                    <div className="text-black-50">Federal</div>
                                    <h2 className="mt-1"
                                        style={{fontSize: '18px', fontWeight: '600'}}>{staticDatas.normas_federal}</h2>
                                </div>
                                <div className=""><img src={grafico1} alt="gráfico1" style={{width: '130px'}}/></div>
                            </div>
                        </div>
                        <div className="" style={{width: '25%'}}>
                            <div
                                className="p-4 bg-white border-0 shadow-sm text-center d-flex justify-content-between align-items-center"
                                style={{borderRadius: '1rem'}}>
                                <div className="d-flex flex-column align-items-start">
                                    <div className="text-black-50">Estadual</div>
                                    <h2 className="mt-1"
                                        style={{fontSize: '18px', fontWeight: '600'}}>{staticDatas.normas_estadual}</h2>
                                </div>
                                <div className=""><img src={grafico2} alt="gráfico2" style={{width: '130px'}}/></div>
                            </div>
                        </div>
                        <div className="" style={{width: '25%'}}>
                            <div
                                className="p-4 bg-white border-0 shadow-sm text-center d-flex justify-content-between align-items-center"
                                style={{borderRadius: '1rem'}}>
                                <div className="d-flex flex-column align-items-start">
                                    <div className="text-black-50">Municipal</div>
                                    <h2 className="mt-1" style={{
                                        fontSize: '18px',
                                        fontWeight: '600'
                                    }}>{staticDatas.normas_municipal}</h2>
                                </div>
                                <div className=""><img src={grafico3} alt="gráfico3" style={{width: '130px'}}/></div>
                            </div>
                        </div>
                        <div className="" style={{width: '19%'}}>
                            <div className="p-4 bg-white border-0 shadow-sm text-center" style={{borderRadius: '1rem'}}>
                                <div className="d-flex flex-column align-items-start">
                                    <div
                                        className="d-flex justify-content-between align-items-center text-black-50 w-100">
                                        <div>Pagamento ICMS</div>
                                        <div style={{fontSize: '20px', lineHeight: '0px'}}><AiOutlineExclamationCircle/>
                                        </div>
                                    </div>
                                    <h2 className="mt-1" style={{fontSize: '18px', fontWeight: '600'}}>27/09/2024</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between mb-4">
                        <div className="border-0 shadow-sm bg-white" style={{borderRadius: '1rem', width: '68%'}}>
                            <div className="">
                                <div className="d-flex py-3 pl-2 pr-4 justify-content-between align-items-center">
                                    <div style={{width: '40%'}}>
                                        <select className="form-control border-0 font-weight-bold bg-light"
                                                style={{borderRadius: '0.5rem'}}>
                                            <option selected value={""}>Últimas Análises</option>
                                            <option value={""}>30 dias</option>
                                            <option value={""}>90 dias</option>
                                        </select>
                                    </div>
                                    <div style={{fontSize: '20px'}}><AiOutlineExclamationCircle/></div>
                                </div>
                                <div className="">
                                    <table className="table table-borderless">
                                        <thead className="bg-light">
                                        <tr>
                                            <th className="pl-3 font-weight-light" style={{width: '60%'}}>NOME</th>
                                            <th className="font-weight-light">FONTE</th>
                                            <th className="font-weight-light">DATA</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {normasData && normasData.normas ? normasData.normas.map(item => (
                                            <tr key={item.id}>
                                                <td className="pl-3 font-weight-light">{item.nome}</td>
                                                <td className="font-weight-light">{item.fonte_nome}</td>
                                                <td className="font-weight-light">{formatDate(item.arquivo_criado)}</td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan="3" className="text-center">Nenuma Norma Encontrada</td>
                                            </tr>
                                        )}
                                        </tbody>
                                        <tfoot className="pl-4 mt-2 d-flex">
                                        <div>Ver mais</div>
                                        <div className="ml-1 d-flex justify-content-start align-items-center"
                                             style={{fontSize: '1.3rem'}}><IoIosArrowRoundForward/></div>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="border-0 shadow-sm bg-white" style={{borderRadius: '1rem', width: '30%'}}>
                            <div className="">
                                <div className="p-3">
                                    <h3>Calendarios Fiscais</h3>
                                </div>
                                <div className="">
                                    <table className="table table-borderless">
                                        <thead className="bg-light">
                                        <tr>
                                            <th className="pl-3 font-weight-light" style={{width: '65%'}}>FONTE</th>
                                            <th className="font-weight-light">DATA</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {calendarData && calendarData ? calendarData.map(item => (
                                            <tr>
                                                <td className="pl-3 font-weight-light">{item.area_nome}</td>
                                                <td className="font-weight-light">{formatDate(item.start)}</td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan="3" className="text-center">Nenuma Data Encontrada</td>
                                            </tr>
                                        )}
                                        </tbody>
                                        <tfoot className="pl-4 mt-2 d-flex">
                                        <div>Ver mais</div>
                                        <div className="ml-1 d-flex justify-content-start align-items-center"
                                             style={{fontSize: '1.3rem'}}><IoIosArrowRoundForward/></div>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between mb-4">
                        <div className="" style={{width: '68%'}}>
                            <div className="border-0 shadow-sm w-100 px-5 pb-4 d-flex flex-column align-items-start"
                                 style={{
                                     borderRadius: '1rem',
                                     backgroundColor: '#205070',
                                     backgroundImage: `url(${city})`,
                                     backgroundPosition: 'center',
                                     backgroundSize: 'cover',
                                     backgroundRepeat: 'no-repeat'
                                 }}>
                                <h2 className="text-warning" style={{fontSize: '27px', fontWeight: '600'}}>Complexidade
                                    Tributária na América Latina</h2>
                                <div className="text-white" style={{fontSize: '12px', fontWeight: '400'}}>Nossa
                                    inteligência rtificial monitora a legislação fiscal em mais de 30 países da América
                                    Latina para fornecer<br></br> inforamções rápidas, precisas e relevantes para seu
                                    negócio em um único report.
                                </div>
                                <div className="w-100 d-flex justify-content-start">
                                    <div style={{backgroundColor: '#F3C70F', borderRadius: '0.5rem', fontWeight: '600'}}
                                         className="mt-3 px-5 py-2">Conheça TaxUpdate LATAM
                                    </div>
                                </div>
                            </div>

                            <h4 className="p-3">Ultimos Posts</h4>

                            {postsData && postsData.posts ? postsData.posts.map(item => (
                                <div
                                    className="border-0 shadow-sm w-100 bg-white px-4 py-4 mb-3 d-flex flex-column align-items-start"
                                    style={{borderRadius: '1rem'}}>
                                    <div className="px-3 py-1 text-white" style={{
                                        backgroundColor: item.tipo === 'roteiro' ? '#043A5E' : '#429A85',
                                        borderRadius: '1rem',
                                        fontWeight: '600'
                                    }}>{item.tipo.charAt(0).toUpperCase() + item.tipo.slice(1)}
                                    </div>
                                    <h2 className="" style={{fontSize: '20px', fontWeight: '600'}}>
                                        {item.titulo}
                                    </h2>
                                    <div className="w-100 d-flex justify-content-between align-items-center">
                                        <div className="d-flex justify-content-start align-items-center">
                                            {/*<div className="rounded-circle p-3 bg-dark"></div>*/}
                                            <div className="ml-2" style={{fontSize: '11px', fontWeight: '400'}}>Por
                                                Thais
                                                Araujo . {formatDate(item.criado)}
                                            </div>
                                        </div>
                                        {/*<div className="">Leitura de 3 min</div>*/}
                                    </div>
                                </div>
                            )) : (
                                <div></div>
                            )}
                        </div>

                        <div className="" style={{width: '30%'}}>
                            <div className="border-0 shadow-sm bg-white py-3" style={{borderRadius: '1rem'}}>
                                <div className="px-3">
                                    <h3>Conversor</h3>
                                </div>
                                <div className="px-3">
                                    <h2 style={{fontSize: '27px', fontWeight: '600'}} id="resultConversor">
                                        $ {valorBid}
                                    </h2>
                                    <form className="form-horizontal">
                                        <label className="mb-1 control-label text-black-50" style={{fontSize: '11px'}}>
                                            Valor
                                        </label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            value={valor}
                                            onChange={(e) => setValor(e.target.value)}
                                            style={{borderRadius: '0.5rem'}}
                                        />
                                        <div className="d-flex justify-content-between align-items-end mt-3">
                                            <div>
                                                <label className="mb-1 control-label text-black-50"
                                                       style={{fontSize: '11px'}}>
                                                    De
                                                </label>
                                                <select
                                                    className="form-control"
                                                    style={{borderRadius: '0.5rem'}}
                                                    value={de}
                                                    onChange={(e) => setDe(e.target.value)}
                                                >
                                                    <option value="USD">USD</option>
                                                    <option value="BRL">BRL</option>
                                                    <option value="ARS">ARS</option>
                                                    <option value="EUR">EUR</option>
                                                    <option value="JPY">JPY</option>
                                                    <option value="GBP">GBP</option>
                                                    <option value="AUD">AUD</option>
                                                    <option value="CAD">CAD</option>
                                                    <option value="CHF">CHF</option>
                                                    <option value="CNY">CNY</option>
                                                    <option value="SEK">SEK</option>
                                                    <option value="NZD">NZD</option>
                                                    <option value="MXN">MXN</option>
                                                    <option value="SGD">SGD</option>
                                                    <option value="HKD">HKD</option>
                                                    <option value="NOK">NOK</option>
                                                    <option value="KRW">KRW</option>
                                                    <option value="TRY">TRY</option>
                                                    <option value="RUB">RUB</option>
                                                    <option value="INR">INR</option>
                                                    <option value="ZAR">ZAR</option>
                                                </select>
                                            </div>
                                            <div style={{fontSize: '20px'}}>
                                                <GoArrowSwitch/>
                                            </div>
                                            <div>
                                                <label className="mb-1 control-label text-black-50"
                                                       style={{fontSize: '11px'}}>
                                                    Para
                                                </label>
                                                <select
                                                    className="form-control"
                                                    style={{borderRadius: '0.5rem'}}
                                                    value={para}
                                                    onChange={(e) => setPara(e.target.value)}
                                                >
                                                    <option value="BRL">BRL</option>
                                                    <option value="USD">USD</option>
                                                    <option value="ARS">ARS</option>
                                                    <option value="EUR">EUR</option>
                                                    <option value="JPY">JPY</option>
                                                    <option value="GBP">GBP</option>
                                                    <option value="AUD">AUD</option>
                                                    <option value="CAD">CAD</option>
                                                    <option value="CHF">CHF</option>
                                                    <option value="CNY">CNY</option>
                                                    <option value="SEK">SEK</option>
                                                    <option value="NZD">NZD</option>
                                                    <option value="MXN">MXN</option>
                                                    <option value="SGD">SGD</option>
                                                    <option value="HKD">HKD</option>
                                                    <option value="NOK">NOK</option>
                                                    <option value="KRW">KRW</option>
                                                    <option value="TRY">TRY</option>
                                                    <option value="RUB">RUB</option>
                                                    <option value="INR">INR</option>
                                                    <option value="ZAR">ZAR</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mt-3 mb-4">
                                            <div className="text-black-50" style={{fontSize: '11px'}}>
                                                {dayjs(createDate).utc(false).format('D [de] MMM., HH:mm:ss [UTC]')}
                                            </div>
                                            <div className="text-black-50"
                                                 style={{fontSize: '11px', textDecoration: 'underline'}}>
                                                Exoneração de responsabilidade
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;
