import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from "./pages/Dashboard";
import Biblioteca from "./pages/Biblioteca";
import StaticalData from "./pages/staticalData/StaticalData";
import Profile from './pages/profile/Index';
import Calendario from "./pages/Calendario";
import Apresentacoes from "./pages/Apresentacoes";
import LeisTraduzidas from "./pages/LeisTraduzidas";
import Leituras from "./pages/Leituras";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route exact path="/" element={<Login />} />
                <Route path={"/dashboard"} element={<Dashboard />} />
                <Route path="/biblioteca" element={<Biblioteca />} />
                <Route path="/calendario" element={<Calendario />} />
                <Route path="/data" element={<StaticalData />} />
                <Route path="/apresentacoes" element={<Apresentacoes />} />
                <Route path="/leis" element={<LeisTraduzidas />} />
                <Route path="/leituras" element={<Leituras />} />
                <Route path="/profile" element={<Profile />} />
            </Routes>
        </Router>
    );
}

export default App;
