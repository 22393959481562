import { useState } from "react";
import Navbar from "../components/Navbar";
import SideMenu from "../components/SideMenu";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

function Calendario() {
    const styles = {
        selectContainer: { position: 'relative', width: '100%', margin: '0 50px 0 0' },
        select: { width: '100%', padding: '20px 10px 10px 10px', fontSize: '12px', border: '1px solid #E5E7EB', borderRadius: '4px', outline: 'none', backgroundColor: '#fff' },
        label: { position: 'absolute', top: '10px', left: '10px', color: '#aaa', fontSize: '10px', pointerEvents: 'none', transform: 'translateY(-50%)', transition: '0.2s ease all' },
    };

    const calendar_styles = {
        calendarContainer: { display: 'flex', flexDirection: 'column', margin: '0 auto' },
        weekDaysContainer: { display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', fontWeight: 'bold', textAlign: 'center' },
        weekDay: { padding: '10px' },
        daysContainer: { display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gridTemplateRows: 'repeat(6, 1fr)' },
        dayBlock: { position: 'relative', width: '100%', padding: '55px', backgroundColor: '#fff', textAlign: 'center' },
        dayNumber: { position: 'absolute', top: '10px', right: '10px', fontSize: '14px' },
        eventsContainer: { position: 'absolute', bottom: '10px', left: '0px', right: '0px', textAlign: 'left', fontSize: '12px' },
        event: { fontSize: '12px', width: '100%', backgroundColor: '#449A86', color: '#fff', paddingLeft: '10px', borderRadius: '5px', fontWeight: '600', marginBottom: '2px' },
    };

    const [year, setYear] = useState(2024);
    const [month, setMonth] = useState(8);

    const allMonths = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    const daysOfWeek = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

    const eventos = [
        { day: 1, title: "Report #132" },
        { day: 1, title: "Data Importante" },
        { day: 4, title: "Report #131" }
    ];

    const today = new Date();
    const todayDate = today.getDate();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    const getDaysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const generateCalendar = (year, month) => {
        const daysInCurrentMonth = getDaysInMonth(year, month);
        const firstDayOfMonth = new Date(year, month, 1).getDay();

        let daysArray = [];

        if (firstDayOfMonth > 0) {
            const prevMonthDays = getDaysInMonth(year, month - 1);
            for (let i = firstDayOfMonth - 1; i >= 0; i--) {
                daysArray.push({ day: prevMonthDays - i, isCurrentMonth: false });
            }
        }

        for (let i = 1; i <= daysInCurrentMonth; i++) {
            daysArray.push({ day: i, isCurrentMonth: true });
        }

        while (daysArray.length < 42) {
            const nextMonthDay = daysArray.length - daysInCurrentMonth - firstDayOfMonth + 1;
            daysArray.push({ day: nextMonthDay, isCurrentMonth: false });
        }

        return daysArray;
    };

    const calendarDays = generateCalendar(year, month);
    const isLastColumn = (index) => (index + 1) % 7 === 0;

    const getEventsForDay = (day) => {
        return eventos.filter((event) => event.day === day && month === 8);
    };

    return (
        <div id="wrapper" style={{ backgroundColor: '#043A5E' }}>
            <SideMenu />

            <div id="page-wrapper" className="pb-5" style={{ backgroundColor: '#FCFCFD' }}>
                <Navbar />

                <div class="pl-2 mt-4 mb-3 custom-normal" style={{ fontSize: '38px', fontWeight: '600' }}>Calendário</div>

                <div class="d-flex justify-content-between align-items-center p-4">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <div className="mr-2" style={{ fontSize: '20px', fontWeight: '600', color: '#111927' }}>{allMonths[month]}</div>
                            <div style={{ fontSize: '20px', fontWeight: '400' }}>2024</div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="mr-4" style={{ fontSize: '25px' }}><RiArrowLeftSLine /></div>
                            <div className="mr-4" style={{ fontSize: '25px' }}><RiArrowRightSLine /></div>
                        </div>
                        <div className="mr-4" style={{}}>
                            <div style={styles.selectContainer}>
                                <select id="fullName" name="fullName" defaultValue="Mais recente" style={styles.select}>
                                    <option>Mês</option>
                                </select>
                                <label htmlFor="fullName" style={styles.label}>
                                    Ver
                                </label>
                            </div>
                        </div>
                        <div className="text-white" style={{ backgroundColor: '#449A86', borderRadius: '16px', fontSize: '14px', padding: '0.7rem 1.2rem' }}>+ Novo Evento</div>
                    </div>
                </div>

                <div className="bg-white border-0 shadow-sm" style={{ borderRadius: '1rem' }}>
                    <div style={calendar_styles.calendarContainer}>
                        <div style={calendar_styles.weekDaysContainer}>
                            {daysOfWeek.map((day, index) => (
                                <div
                                    style={{
                                        ...calendar_styles.weekDay,
                                        borderRight: isLastColumn(index) ? 'none' : '1px solid #e0e0e0'
                                    }}
                                    key={day}>
                                    {day}
                                </div>
                            ))}
                        </div>

                        <div style={calendar_styles.daysContainer}>
                            {calendarDays.map((dayObj, index) => {
                                const events = getEventsForDay(dayObj.day);
                                const isToday = dayObj.isCurrentMonth && dayObj.day === todayDate && year === currentYear && month === currentMonth;

                                return (
                                    <div
                                        style={{
                                            ...calendar_styles.dayBlock,
                                            color: dayObj.isCurrentMonth ? '#101927' : '#C6C6C6',
                                            backgroundColor: isToday ? '#FFDEBF' : '#fff',
                                            borderTop: '1px solid #e0e0e0',
                                            borderRight: isLastColumn(index) ? 'none' : '1px solid #e0e0e0',
                                        }}
                                        key={index}
                                    >
                                        <span style={calendar_styles.dayNumber}>{dayObj.day}</span>

                                        {events.length > 0 && dayObj.isCurrentMonth && (
                                            <div style={calendar_styles.eventsContainer}>
                                                {events.map((event, idx) => (
                                                    <div key={idx} style={calendar_styles.event}>
                                                        {event.title}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Calendario;
