import { BsThreeDots } from "react-icons/bs";

function Time() {
    return (
        <div class="bg-white shadow-sm d-flex flex-column justify-content-between" style={{ borderRadius: '1rem' }}>
            <div className="p-4" style={{ fontSize: '14px', fontWeight: '600' }}>Time</div>
            <div className="mb-3">
                <div className="d-flex justify-content-between py-2 px-3 bg-light" style={{ fontSize: '13px', fontWeight: '600' }}>
                    <div style={{ width: '70%' }}>USUÁRIOS</div>
                    <div style={{ width: '20%' }}>PERMISSÕES</div>
                    <div style={{ width: '10%' }}></div>
                </div>
                <div className="d-flex justify-content-between align-items-center py-2 px-3" style={{ fontSize: '14px', fontWeight: '500', borderTop: '1px solid #F2F4F7' }}>
                    <div style={{ width: '70%' }}>
                        <div class="d-flex align-items-center p-2">
                            <div class="mr-3" style={{ width: '45px', height: '45px', borderRadius: '50%', backgroundColor: '#E5E7EB', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src="/path/to/your/image.jpg" alt="" style={{ width: '45px', height: '45px', borderRadius: '50%' }}/>
                            </div>
                            <div>
                                <div class="custom-normal mb-1" style={{ fontSize: '13px', fontWeight: '500' }}>Tom Zhu</div>
                                <div style={{ fontSize: '13px', fontWeight: '500' }}>tom.zhu@tesla.com</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '20%' }}>
                        <div class="py-1 text-center" style={{ backgroundColor: '#E2F7F1', color: '#08815B', borderRadius: '1rem', fontSize: '12px', fontWeight: '600', width: '85px', letterSpacing: '0.7px' }}>GERENTE</div>
                    </div>
                    <div className="d-flex justify-content-end align-items-center" style={{ width: '10%', fontSize: '20px' }}><BsThreeDots /></div>
                </div>
                <div className="d-flex justify-content-between align-items-center py-2 px-3" style={{ fontSize: '14px', fontWeight: '500', borderTop: '1px solid #F2F4F7' }}>
                    <div style={{ width: '70%' }}>
                        <div class="d-flex align-items-center p-2">
                            <div class="mr-3" style={{ width: '45px', height: '45px', borderRadius: '50%', backgroundColor: '#E5E7EB', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src="/path/to/your/image.jpg" alt="" style={{ width: '45px', height: '45px', borderRadius: '50%' }}/>
                            </div>
                            <div>
                                <div class="custom-normal mb-1" style={{ fontSize: '13px', fontWeight: '500' }}>Vaibhav Taneja</div>
                                <div style={{ fontSize: '13px', fontWeight: '500' }}>vaibhav.taneja@tesla.com</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '20%' }}>
                        <div class="py-1 text-center" style={{ backgroundColor: 'transparent', color: '#101928', borderRadius: '1rem', fontSize: '12px', fontWeight: '600', width: '85px', letterSpacing: '0.7px' }}>Membro</div>
                    </div>
                    <div className="d-flex justify-content-end align-items-center" style={{ width: '10%', fontSize: '20px' }}><BsThreeDots /></div>
                </div>
            </div>
        </div>
    )
}

export default Time;
