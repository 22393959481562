function Geral() {
    const styles = {
        inputContainer: {position: 'relative', width: '100%' },
        input: {width: '100%', padding: '20px 10px 10px 10px', fontSize: '14px', border: '1px solid #ccc', borderRadius: '4px', outline: 'none' },
        label: {position: 'absolute', top: '10px', left: '10px', color: '#aaa', fontSize: '10px', pointerEvents: 'none', transform: 'translateY(-50%)', transition: '0.2s ease all' },
    };

    return (
        <div class="p-4 bg-white shadow-sm d-flex justify-content-between" style={{ borderRadius: '1rem' }}>
            <div style={{ fontSize: '14px', fontWeight: '600' }}>Detalhes</div>
            <div className="d-flex flex-column align-items-start" style={{ width: '50%' }}>
                <div className="d-flex align-items-center">
                    <div className="mr-4" style={{ width: '100px', height: '100px', border: '3px dotted #D1D6DA', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f0f0f0' }}>
                        <img src="/path/to/your/image.jpg" alt="profile" />
                    </div>
                    <div>Alterar</div>
                </div>
                <div className="mt-4 d-flex align-items-center w-100" style={{ position: 'relative' }}>
                    <div style={styles.inputContainer}>
                        <input type="text" id="fullName" name="fullName" defaultValue="Elon Musk" style={styles.input} />
                        <label htmlFor="fullName" style={styles.label}>
                            Full Name
                        </label>
                    </div>
                    <div className="custom-normal" style={{ position: 'absolute', right: '-4rem' }}>Salvar</div>
                </div>
                <div className="mt-4 d-flex align-items-center w-100">
                    <div style={styles.inputContainer}>
                        <input type="text" id="fullName" name="fullName" defaultValue="Elon Musk" style={styles.input} />
                        <label htmlFor="fullName" style={styles.label}>
                            Full Name
                        </label>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    )
}

export default Geral;
