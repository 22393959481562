import React, { useState } from 'react';

import SideMenu from '../../components/SideMenu';
import Navbar from "../../components/Navbar";
import Geral from './Geral';
import Cobranca from './Cobranca';
import Seguranca from './Seguranca';
import Notificacao from './Notificacao';
import Time from './Time';

function Profile() {
    const [activeMenu, setActiveMenu] = useState('Geral');

    // Função para trocar o conteúdo com base no menu clicado
    const renderContent = () => {
        switch (activeMenu) {
            case 'Geral':
                return <Geral />;
            case 'Cobranças':
                return <Cobranca />;
            case 'Time':
                return <Time />;
            case 'Notificações':
                return <Notificacao />;
            case 'Segurança':
                return <Seguranca />;
            default:
                return null;
        }
    };

    return (
        <div id="wrapper" style={{ backgroundColor: '#043A5E' }}>
            <SideMenu />

            <div id="page-wrapper" style={{ backgroundColor: '#FCFCFD' }}>
                <Navbar />

                <div class="pl-4 mt-4 custom-normal" style={{ fontSize: '38px', fontWeight: '600' }}>Conta</div>

                <div class="wrapper wrapper-content">
                    <div>
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <ul className="nav nav-pills w-100" style={{ borderBottom: '1px solid #F3F4F7' }}>
                                <li className="">
                                    <div
                                        className={`mr-5 ${activeMenu === 'Geral' ? 'active-menu' : ''}`}
                                        style={{ cursor: 'pointer', paddingBottom: '10px' }}
                                        onClick={() => setActiveMenu('Geral')}
                                    >
                                        Geral
                                    </div>
                                </li>
                                <li className="">
                                    <div
                                        className={`mr-5 ${activeMenu === 'Cobranças' ? 'active-menu' : ''}`}
                                        style={{ cursor: 'pointer', paddingBottom: '10px' }}
                                        onClick={() => setActiveMenu('Cobranças')}
                                    >
                                        Cobranças
                                    </div>
                                </li>
                                <li className="">
                                    <div
                                        className={`mr-5 ${activeMenu === 'Time' ? 'active-menu' : ''}`}
                                        style={{ cursor: 'pointer', paddingBottom: '10px' }}
                                        onClick={() => setActiveMenu('Time')}
                                    >
                                        Time
                                    </div>
                                </li>
                                <li className="">
                                    <div
                                        className={`mr-5 ${activeMenu === 'Notificações' ? 'active-menu' : ''}`}
                                        style={{ cursor: 'pointer', paddingBottom: '10px' }}
                                        onClick={() => setActiveMenu('Notificações')}
                                    >
                                        Notificações
                                    </div>
                                </li>
                                <li className="">
                                    <div
                                        className={`mr-5 ${activeMenu === 'Segurança' ? 'active-menu' : ''}`}
                                        style={{ cursor: 'pointer', paddingBottom: '10px' }}
                                        onClick={() => setActiveMenu('Segurança')}
                                    >
                                        Segurança
                                    </div>
                                </li>
                            </ul>
                        </nav>

                        <div className="content" style={{ marginTop: '20px' }}>
                            {renderContent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;
