import { useState } from "react";
import Navbar from "../components/Navbar";
import SideMenu from "../components/SideMenu";

function Leituras() {
    return (
        <div id="wrapper" style={{ backgroundColor: '#043A5E' }}>
            <SideMenu />

            <div id="page-wrapper" className="pb-5" style={{ backgroundColor: '#FCFCFD' }}>
                <Navbar />

                <div class="pl-2 mt-4 mb-3 custom-normal" style={{ fontSize: '38px', fontWeight: '600' }}>Leituras Técnicas</div>
            </div>
        </div>
    );
}

export default Leituras;
