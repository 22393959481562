function Cobranca() {
    return (
        <div class="bg-white shadow-sm d-flex flex-column justify-content-between" style={{ borderRadius: '1rem' }}>
            <div className="p-4" style={{ fontSize: '14px', fontWeight: '600' }}>Histórico de Cobrança</div>
            <div className="mb-3">
                <div className="d-flex justify-content-between py-2 px-3 bg-light" style={{ fontSize: '13px', fontWeight: '600' }}>
                    <div style={{ width: '40%' }}>DATA</div>
                    <div style={{ width: '40%' }}>TOTAL (INCL. TAX)</div>
                    <div style={{ width: '20%' }}></div>
                </div>
                <div className="d-flex justify-content-between py-2 px-3" style={{ fontSize: '14px', fontWeight: '500', borderTop: '1px solid #F2F4F7' }}>
                    <div style={{ width: '40%' }}>01 Jan 2024</div>
                    <div style={{ width: '40%' }}>R$1299.99</div>
                    <div style={{textDecoration: 'underline', width: '20%', textAlign: 'end'}}>Ver Invoice</div>
                </div>
                <div className="d-flex justify-content-between py-2 px-3" style={{ fontSize: '14px', fontWeight: '500', borderTop: '1px solid #F2F4F7' }}>
                    <div style={{ width: '40%' }}>01 Jan 2024</div>
                    <div style={{ width: '40%' }}>R$1299.99</div>
                    <div style={{textDecoration: 'underline', width: '20%', textAlign: 'end'}}>Ver Invoice</div>
                </div>
                <div className="d-flex justify-content-between py-2 px-3" style={{ fontSize: '14px', fontWeight: '500', borderTop: '1px solid #F2F4F7' }}>
                    <div style={{ width: '40%' }}>01 Jan 2024</div>
                    <div style={{ width: '40%' }}>R$1299.99</div>
                    <div style={{textDecoration: 'underline', width: '20%', textAlign: 'end'}}>Ver Invoice</div>
                </div>
            </div>
        </div>
    )
}

export default Cobranca;
