import React, { useState } from 'react';

import { CiSearch } from "react-icons/ci";
import { GoBell } from "react-icons/go";
import { GoPeople } from "react-icons/go";

import bandeira from '../img/header-flag.png';

function Navbar() {
    const [showNotifications, setShowNotifications] = useState(false);

    const toggleNotifications = () => {
        setShowNotifications(!showNotifications);
    };

    return (
        <div class="row">
            <nav class="navbar navbar-static-top white-bg" role="navigation">
                <div class="navbar-header">
                    <form role="search" class="navbar-form-custom ml-4" action="#">
                        <div class="form-group align-items-center d-flex">
                            <CiSearch style={{ left: '2rem', color: '#232730', fontSize: '24px' }} />
                            <input type="text" class="form-control" name="top-search" id="top-search" />
                        </div>
                    </form>
                </div>
                <div className='d-flex justify-content-between align-items-center pr-4' style={{ width: '20%' }}>
                    <div><img src={bandeira} alt="band" style={{ width: '28px' }}/></div>
                    <ul className="nav navbar-top-links">
                        <li className="dropdown">
                            <a className="dropdown-toggle count-info" onClick={toggleNotifications} href="#">
                                <GoBell style={{ fontSize: '18px', padding: '0', color: '#232730' }} />
                                <span className="label label-primary" style={{ borderRadius: '50%' }}>2</span>
                            </a>

                            {showNotifications && (
                                <ul className="dropdown-menu dropdown-alerts" style={{ display: showNotifications ? 'block' : 'none' }}>
                                    <li>
                                        <a href="#" className="dropdown-item">
                                            <div>
                                                <i className="fa fa-envelope fa-fw"></i> You have 16 messages
                                                <span className="float-right text-muted small">4 minutes ago</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="dropdown-divider"></li>
                                    <li>
                                        <a href="/profile" className="dropdown-item">
                                            <div>
                                                <i className="fa fa-twitter fa-fw"></i> 3 New Followers
                                                <span className="float-right text-muted small">12 minutes ago</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="#"></li>
                                    <li>
                                        <a href="grid_options.html" className="dropdown-item">
                                            <div>
                                                <i className="fa fa-upload fa-fw"></i> Server Rebooted
                                                <span className="float-right text-muted small">4 minutes ago</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="dropdown-divider"></li>
                                    <li>
                                        <div className="text-center link-block">
                                            <a href="notifications.html" className="dropdown-item">
                                                <strong>See All Alerts</strong>
                                                <i className="fa fa-angle-right"></i>
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            )}
                        </li>
                    </ul>
                    <a href='/profile'><GoPeople style={{ fontSize: '18px', padding: '' }} /></a>
                    <div style={{ width: '30px', height: '30px', borderRadius: '50%' }}><img src="/path/to/your/image.jpg" alt="profile" /></div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;