import { IoIosArrowRoundForward } from "react-icons/io";

import SideMenu from '../../components/SideMenu';
import ChartData from './ChartData';
import DonutData from './DonutData';
import Navbar from "../../components/Navbar";

function StaticalData() {
    return (
        <div id="wrapper" style={{ backgroundColor: '#043A5E' }}>
            <SideMenu />

            <div id="page-wrapper" style={{ backgroundColor: '#FCFCFD' }}>
                <Navbar />

                <div class="pl-4 mt-4 custom-normal" style={{ fontSize: '34px', fontWeight: '600' }}>Dados Estatísticos</div>

                <div class="wrapper wrapper-content">
                    <div class="d-flex justify-content-between mb-4">
                        <div style={{ borderRadius: '1rem', width: '68%' }}>
                            <div class="p-4 border-0 shadow-sm bg-white" style={{ borderRadius: '1rem' }}>
                                <div class="custom-normal" style={{ fontSize: '16px', fontWeight: '600' }}>Normas Publicadas</div>
                                <div class="d-flex justify-content-between mt-4">
                                    <div class="" style={{ width: '25%' }}>
                                        <div class="card p-4 rounded-pill border-0 shadow-sm text-center" style={{ borderRadius: '1rem', background: '#ECF5F3' }}>
                                            <div class="d-flex flex-column align-items-start">
                                                <div class="text-black-50">Ultimos 30 dias</div>
                                                <h2 class="mt-1 custom-normal" style={{ fontSize: '20px', fontWeight: '600' }}>87</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" style={{ width: '25%' }}>
                                        <div class="card p-4 rounded-pill border-0 shadow-sm text-center" style={{ borderRadius: '1rem', background: '#ECF5F3' }}>
                                            <div class="d-flex flex-column align-items-start">
                                                <div class="text-black-50">Ultimos 90 dias</div>
                                                <h2 class="mt-1 custom-normal" style={{ fontSize: '20px', fontWeight: '600' }}>297</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="" style={{ width: '25%' }}>
                                        <div class="card p-4 rounded-pill border-0 shadow-sm text-center" style={{ borderRadius: '1rem', background: '#ECF5F3' }}>
                                            <div class="d-flex flex-column align-items-start">
                                                <div class="text-black-50">Ultimos 365 dias</div>
                                                <h2 class="mt-1 custom-normal" style={{ fontSize: '20px', fontWeight: '600' }}>16.786</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mt-4 p-4 border-0 shadow-sm bg-white" style={{ borderRadius: '1rem' }}>
                                <div class="custom-normal mb-3" style={{ fontSize: '16px', fontWeight: '600' }}>Publicadas</div>
                                <ChartData />
                            </div>
                        </div>

                        <div style={{ borderRadius: '1rem', width: '30%' }}>
                            <div class="border-0 shadow-sm bg-white d-flex flex-column justify-content-between" style={{ borderRadius: '1rem' }}>
                                <div class="">
                                    <div class="p-3">
                                        <h3>Normas por esfera</h3>
                                    </div>
                                    <div class="bg-light d-flex justify-content-between alignm-items-center" style={{ padding: '0.8rem 0 0.8rem 0' }}>
                                        <div class="pl-3" style={{ width: '70%' }}>FONTE</div>
                                        <div style={{ width: '30%' }}>DATA</div>
                                    </div>
                                    <div class="d-flex justify-content-between alignm-items-center" style={{ padding: '0.8rem 0 0.8rem 0' }}>
                                        <div class="pl-3" style={{ width: '70%' }}>União</div>
                                        <div style={{ width: '30%' }}>1.243</div>
                                    </div>
                                    <div class="d-flex justify-content-between alignm-items-center" style={{ padding: '0.8rem 0 0.8rem 0', borderTop: '0.5px solid #F7F8FB' }}>
                                        <div class="pl-3" style={{ width: '70%' }}>Pernambuco</div>
                                        <div style={{ width: '30%' }}>43</div>
                                    </div>
                                    <div class="d-flex justify-content-between alignm-items-center" style={{ padding: '0.8rem 0 0.8rem 0', borderTop: '0.5px solid #F7F8FB' }}>
                                        <div class="pl-3" style={{ width: '70%' }}>Rio de Janeiro</div>
                                        <div style={{ width: '30%' }}>236</div>
                                    </div>
                                    <div class="d-flex justify-content-between alignm-items-center" style={{ padding: '0.8rem 0 0.8rem 0', borderTop: '0.5px solid #F7F8FB' }}>
                                        <div class="pl-3" style={{ width: '70%' }}>Rio Grande do Sul</div>
                                        <div style={{ width: '30%' }}>56</div>
                                    </div>
                                    <div class="d-flex justify-content-between alignm-items-center" style={{ padding: '0.8rem 0 0.8rem 0', borderTop: '0.5px solid #F7F8FB' }}>
                                        <div class="pl-3" style={{ width: '70%' }}>Rio de Janeiro (RJ)</div>
                                        <div style={{ width: '30%' }}>87</div>
                                    </div>
                                </div>
                                <div class="pl-4 mb-3 mt-2 d-flex">
                                    <div>Ver mais</div>
                                    <div class="ml-1 d-flex justify-content-start align-items-center" style={{ fontSize: '1.3rem' }}><IoIosArrowRoundForward /></div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="d-flex justify-content-between mb-4">
                        <div style={{ borderRadius: '1rem', width: '68%' }}>
                            <div class="mt-4 p-4 border-0 shadow-sm bg-white" style={{ borderRadius: '1rem' }}>
                                <div class="custom-normal" style={{ fontSize: '16px', fontWeight: '600' }}>Por estado</div>
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex justify-content-center" style={{ width: '65%' }}><img src="/path/to/your/image.jpg" alt="Gráfico de imagens" /></div>
                                    <div style={{ width: '35%' }}>
                                        <div class="text-black-50">Total</div>
                                        <h2 class="mt-1 custom-normal" style={{ fontSize: '20px', fontWeight: '600' }}>12.325</h2>

                                        <div class="d-flex align-items-center mt-4">
                                            <div class="mr-3" style={{ width: '45px', height: '45px', borderRadius: '50%', backgroundColor: '#E5E7EB' }}>
                                                <img src="/path/to/your/image.jpg" alt="" style={{ width: '45px', height: '45px', borderRadius: '50%' }}/>
                                            </div>
                                            <div style={{ width: '75%' }}>
                                                <div class="custom-normal mb-2">Brasil</div>
                                                <div class="d-flex align-items-center">
                                                    <div className="progress-bar-container">
                                                        <div className="progress-bar" style={{ width: `${60}%` }}>
                                                            <span style={{ padding: '0 10px 0 0' }}></span>
                                                        </div>
                                                    </div>
                                                    <div style={{ fontSize: '15px', fontWeight: '500', margin: '0 0 0 1rem' }}>60%</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mt-4">
                                            <div class="mr-3" style={{ width: '45px', height: '45px', borderRadius: '50%', backgroundColor: '#E5E7EB' }}>
                                                <img src="/path/to/your/image.jpg" alt="" style={{ width: '45px', height: '45px', borderRadius: '50%' }}/>
                                            </div>
                                            <div style={{ width: '75%' }}>
                                                <div class="custom-normal mb-2">Paraná</div>
                                                <div class="d-flex align-items-center">
                                                    <div className="progress-bar-container">
                                                        <div className="progress-bar" style={{ width: `${20}%` }}>
                                                            <span style={{ padding: '0 10px 0 0' }}></span>
                                                        </div>
                                                    </div>
                                                    <div style={{ fontSize: '15px', fontWeight: '500', margin: '0 0 0 1rem' }}>20%</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mt-4">
                                            <div class="mr-3" style={{ width: '45px', height: '45px', borderRadius: '50%', backgroundColor: '#E5E7EB' }}>
                                                <img src="/path/to/your/image.jpg" alt="" style={{ width: '45px', height: '45px', borderRadius: '50%' }}/>
                                            </div>
                                            <div style={{ width: '75%' }}>
                                                <div class="custom-normal mb-2">Pernambuco</div>
                                                <div class="d-flex align-items-center">
                                                    <div className="progress-bar-container">
                                                        <div className="progress-bar" style={{ width: `${10}%` }}>
                                                            <span style={{ padding: '0 10px 0 0' }}></span>
                                                        </div>
                                                    </div>
                                                    <div style={{ fontSize: '15px', fontWeight: '500', margin: '0 0 0 1rem' }}>10%</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mt-4">
                                            <div class="mr-3" style={{ width: '45px', height: '45px', borderRadius: '50%', backgroundColor: '#E5E7EB' }}>
                                                <img src="/path/to/your/image.jpg" alt="" style={{ width: '45px', height: '45px', borderRadius: '50%' }}/>
                                            </div>
                                            <div style={{ width: '75%' }}>
                                                <div class="custom-normal mb-2">São Paulo</div>
                                                <div class="d-flex align-items-center">
                                                    <div className="progress-bar-container">
                                                        <div className="progress-bar" style={{ width: `${5}%` }}>
                                                            <span style={{ padding: '0 10px 0 0' }}></span>
                                                        </div>
                                                    </div>
                                                    <div style={{ fontSize: '15px', fontWeight: '500', margin: '0 0 0 1rem' }}>5%</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex align-items-center mt-4">
                                            <div class="mr-3" style={{ width: '45px', height: '45px', borderRadius: '50%', backgroundColor: '#E5E7EB' }}>
                                                <img src="/path/to/your/image.jpg" alt="" style={{ width: '45px', height: '45px', borderRadius: '50%' }}/>
                                            </div>
                                            <div style={{ width: '75%' }}>
                                                <div class="custom-normal mb-2">Rio de Janeiro</div>
                                                <div class="d-flex align-items-center">
                                                    <div className="progress-bar-container">
                                                        <div className="progress-bar" style={{ width: `${5}%` }}>
                                                            <span style={{ padding: '0 10px 0 0' }}></span>
                                                        </div>
                                                    </div>
                                                    <div style={{ fontSize: '15px', fontWeight: '500', margin: '0 0 0 1rem' }}>5%</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ borderRadius: '1rem', width: '30%' }}>
                            <div class="mt-4 border-0 shadow-sm bg-white d-flex flex-column justify-content-between" style={{ borderRadius: '1rem' }}>
                                <div class="">
                                    <div class="p-3 mb-4">
                                        <h3>Placeholder</h3>
                                        <div class="font-weight-light">Teste</div>
                                    </div>
                                    <DonutData />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StaticalData;
